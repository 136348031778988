/*---------------------------*\
* #TABS
\*---------------------------*/

/* Side Navigation Tooltip */
.nav-item-tooltip {
	left: -6px !important;
	pointer-events: none;
	opacity: 1 !important;

	.tooltip-inner {
		background-color: $brand-primary-medium;
		border-radius: 0;
		padding: 11px 20px 12px;
		font-family: $headings-font-family;
		font-size: $font-size-large;
		font-weight: 600;
		text-transform: uppercase;
	}

	&.bs-tooltip-end .tooltip-arrow::before {
		border-right-color: $brand-primary-medium !important;
	}
}

/* Build Accounting Process modal */
.build-accounting-process-tooltip {
	opacity: 1 !important;

	.tooltip-inner {
		max-width: 480px;
		padding: 30px;
		background-color: $white !important;
		box-shadow: rgba(0, 0, 0, .15) 0 10px 10px;
		text-align: left;
		color: $gray-dark;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before {
		border-bottom-color: $white;

	}
}

/* APEX CHARTS TOOLTIP */
.chart-tooltip {
	padding: 4px 6px;
	font-size: 12px;
	color: $gray-darker;
}
﻿/*---------------------------*\
* #FORMS
\*---------------------------*/

$input-bg: $white;
$input-bg-disabled: $gray-light;
$input-color: $gray;
$input-color-placeholder: $gray-medium;
$input-border: $border-color;
$input-border-radius: $border-radius-base;
$input-border-focus: $brand-highlight;

$legend-color: $gray;
$legend-border-color: $gray-light;

// Textual input addons
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border;

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select.form-control,
select.form-select,
button.form-select,
textarea.form-control {
	font-weight: 500;
	border: none;
	border-bottom: 1px solid $border-color;

	&:focus {
		border: none;
		box-shadow: none;
		border-bottom: 2px solid $brand-success;
	}
}

.form-control {
	font-weight: 500;
}

textarea.form-control {
	height: 45px;
}

.form-control-transparent,
.form-control-transparent:focus,
.form-control-transparent:active,
.form-control-transparent[disabled] {
	background: rgba(0, 0, 0, 0);
}

.form-control-sm {
	min-height: unset;
	height: $input-height-sm;
}

/* Select fields */
/* Since form-select uses its own caret, this overrides the possibility of two carets displaying if a field has both classes presents */
select.form-control {
	-webkit-appearance: auto;
	appearance: auto;

	&.form-select {
		-webkit-appearance: none;
		appearance: none;
	}
}

select.form-select,
button.form-select {
	height: calc(2em + 2px);
	padding: 0.125rem 2.25rem 0.125rem 0.5rem;
	font-size: $font-size-base;
	border-radius: 0;
	background-position: right 0.5rem center;
	background-size: 10px 12px;

	&[disabled] {
		background-image: none;
	}
}

select.form-control[size],
select.form-control[multiple],
select.form-select[size],
select.form-select[multiple] {
	height: auto;
}

/* allowing button dropdown to be styled like a select dropdown but still function like a button
	- for accessibility, this should still be okay and revert back to default behavior */
button.form-select::after {
	content: none;
}

/* Radios & Checkboxes */
input[type="checkbox"] {
	height: 13px;
	width: 13px;
	outline: none;
}

input[type="radio"] {
	height: 13px;
	width: 13px;
	margin-right: 5px;
	position: relative;
	-webkit-appearance: none;
	outline: none;
}

input[type="radio"]:after,
input[type="checkbox"]:after {
	content: "";
	background: $white;
	pointer-events: none;
	position: relative;
	top: -1px;
	left: -1px;
	display: block;
	width: 14px;
	height: 14px;
	color: transparent;
	transition: 0.25s all ease-in-out, padding-left 0s;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	border-width: 1px;
	border-style: solid;
	border-color: $gray-medium-lighter;
}

input[type="radio"]:not(:disabled):hover:after,
input[type="checkbox"]:not(:disabled):hover:after {
	border-color: $brand-highlight;
}

input[type="radio"]:disabled:hover:after,
input[type="checkbox"]:disabled:hover:after {
	background-color: $gray-light !important;
	border-color: lighten($brand-danger-light, 20%) !important;
}

input[type="checkbox"] {
	&:after {
		font-size: 10px;
		padding-left: 1px;
		border-radius: 3px;
		line-height: 1.25;
	}

	&:checked:after {
		content: "\f00c";
		font-family: "Font Awesome 5 Pro";
		color: $white;
		border-color: $brand-highlight;
		background-color: $brand-highlight;
	}

	&:checked:disabled:after {
		content: "\f00c";
		font-family: "Font Awesome 5 Pro";
		color: $white;
		border-color: $brand-highlight;
		background-color: lighten($brand-highlight, 30%);
	}

	&:indeterminate:after {
		content: "\f068";
		font-family: "Font Awesome 5 Pro";
		padding-left: 2px;
		color: $white;
		border-color: $brand-highlight;
		background-color: $brand-highlight;
	}
}

input[type="radio"] {
	&:after {
		/* these need to be included for alignment purposes */
		content: "\f111";
		font-family: "Font Awesome 5 Pro";
		line-height: 2;
		font-size: 6px;
		padding-left: 3px;
		border-radius: 50%;
	}

	&:checked:after {
		content: "\f111";
		font-family: "Font Awesome 5 Pro";
		/* for FA Solid */
		font-weight: 900;
		color: $white;
		line-height: 2;
		border-color: $brand-highlight;
		background-color: $brand-highlight;
	}
}

/* Fix that really annoying alignment for checkbox/label */
.form-check-label {
	margin-top: 1px;
	font-weight: 500;
}

/* Toggle Switches */
.form-switch {

	input[type="checkbox"]:after,
	input[type="checkbox"].checked:after,
	input[type="checkbox"].checked:disabled:after,
	input[type="checkbox"]:disabled:after,
	input[type="checkbox"].indeterminate:after {
		content: none;
	}

	.form-check-input {
		width: 1.3rem;
		height: .85rem;
		margin-top: 7px;
		border-color: $gray-medium-light;
		background-color: $gray-medium-light;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23fff'/%3e%3c/svg%3e");

		&:focus {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23fff'/%3e%3c/svg%3e");
		}

		&:checked {
			border-color: $brand-success;
			background-color: $brand-success;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23fff'/%3e%3c/svg%3e");
		}
	}

	.form-check-label {
		line-height: 1.55rem;
	}

	&.form-switch-inline {
		min-height: unset;
	}
}


/* ERROR STATES */

.has-error {

	input[type="date"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	select.form-control:not(.no-validate),
	select.form-select:not(.no-validate),
	textarea.form-control {
		color: $brand-danger-dark;
		border-bottom: 1px solid $brand-danger-dark;

		&:focus {
			border-bottom: 2px solid $brand-danger;
		}
	}

	.help-block {
		color: $brand-danger-dark;
	}

	.input-group-text,
	.input-group-append .input-group-text {
		color: $brand-danger;
		border-bottom: 1px solid $brand-danger-dark;
	}
}

.has-warning {

	input[type="date"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	select.form-control,
	select.form-select,
	textarea.form-control {
		color: $brand-warning-dark;
		border-bottom: 1px solid $brand-warning-dark;

		&:focus {
			border-bottom: 2px solid $brand-warning;
		}
	}

	.help-block {
		color: $brand-warning-dark;
	}

	.input-group-text,
	.input-group-append .input-group-text {
		color: $brand-warning;
		border-bottom: 1px solid $brand-warning-dark;
	}
}

/* Misc styling */
.form-check.form-check-inline {
	margin: 0.5rem;
}

.form-check-input:focus {
	box-shadow: none;
}

.input-group-append .btn:not(.btn-success) {
	color: $gray-medium-light;

	&:hover {
		color: $brand-primary;
	}
}

.input-group-text {
	font-size: 1rem;
	color: $gray-medium-lighter;
	background-color: rgba(0, 0, 0, 0);
	border: 0 none;
	border-bottom: 1px solid $border-color;
	border-radius: 0;

	.input-group-append & {
		color: $gray-medium-light;
	}
}

.input-group-text {
	padding: 0.35rem 0.75rem;
}

ul.help-block {
	margin-bottom: 0.5rem;
}
﻿/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@import "bootstrap/scss/bootstrap";

// Theme mixins & variables, must be included before the libraries to allow overriding defaults
@import "utilities/mixins";
@import "utilities/variables";

// 3rd party libraries
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800');
@import "@angular/cdk/overlay-prebuilt.css";

// this destroys ng build, need to replace with more targeted scss OR prebuilt css
//@import "~progress/kendo-theme-bootstrap/";

// Base
@import "base/base";
@import "base/typography";

// Theme customization
@import "themes/theme";
@import "themes/login";
@import "themes/admin";

// Layout
@import "layout/header";
@import "layout/navigation";
@import "layout/footer";
@import "layout/content";
@import "layout/sidepanel";
@import "layout/modal";

// Components
@import "components/associate";
@import "components/badge";
@import "components/buttons";
@import "components/char-data";
@import "components/datemath";
@import "components/draggable";
@import "components/dropdowns";
@import "components/form";
@import "components/icon-alert";
@import "components/pagination";
@import "components/panel";
@import "components/popover";
@import "components/search";
@import "components/scroll";
@import "components/table";
@import "components/tabs";
@import "components/tooltip";

// Pages
@import "pages/activity-policy";

// Utilities
@import "utilities/print";

// Vendor overrides
@import "vendors/bootstrap";
@import "vendors/archwizard";
@import "vendors/izenda_overrides";
@import "vendors/cdk";
﻿@import "@achimha/angular-archwizard/styles/archwizard.scss";

/*---------------------------*\
* #WIZARDS
\*---------------------------*/

.rl-wizard .horizontal.small {
	ul.steps-indicator {
		padding: 0;

		li {
			margin: 24px 0 10px 0;

			.step-indicator,
			&.default .step-indicator,
			&.optional .step-indicator,
			&.navigable .step-indicator {
				background-color: $gray-medium-lighter !important;
			}

			&.done .step-indicator {
				background-color: $brand-primary-medium-dark !important;
			}

			&.current .step-indicator,
			&.editing .step-indicator {
				background-color: $brand-success !important;
			}

			/* hides wizard step if there is only one step */
			&:only-of-type {
				display: none;
			}
		}
	}
}
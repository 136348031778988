/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

body {
	font-size: $font-size-base;
	font-family: $font-family-base;
	font-weight: 500;
	color: $gray-dark;
	letter-spacing: 0.005rem;
	word-spacing: 0.1rem;
}

a,
a:focus,
a:visited {
	color: $brand-highlight;
}

a:hover {
	color: $brand-primary;
}

/* Defines entire page layout */

.head-main {
	grid-area: header;
}

/*.head-content {
    grid-area: content-head;
}*/

.content {
	grid-area: content;
}

.nav-main {
	grid-area: nav;
}

.footer-main {
	grid-area: footer;
}

.wrapper {
	height: 100vh;
	display: grid;
	grid-template-rows: $page-head-height 1fr;
	grid-template-columns: $side-nav-compact 1fr;
	grid-template-areas: "header header" "nav content";
	z-index: 1;
}

.wrapper.is-logged-out {
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	grid-template-areas: "content";

	rl-side-navigation,
	rl-header {
		display: none !important;
	}
}


/* hide spinner */
#nprogress .spinner {
	display: none;
}


/* cursors */
.cursor-pointer {
	cursor: pointer;
}

.shiny__loading__bg {
	position: relative;
	overflow: hidden;
	display: inline-block;
	border-radius: 5px;
	color: $white;
	text-decoration: none;
	text-align: center;
	background-color: $gray-light;
}

.shiny__loading__bg:after {
	animation: shine 1.5s ease-in-out infinite;
	animation-fill-mode: forwards;
	content: "";
	position: absolute;
	top: -110%;
	left: -210%;
	width: 200%;
	height: 200%;
	opacity: 0;
	transform: rotate(30deg);

	background: rgba(255, 255, 255, 0.13);
	background: linear-gradient(to right,
			rgba(255, 255, 255, 0.13) 0%,
			rgba(255, 255, 255, 0.23) 77%,
			rgba(255, 255, 255, 0.6) 92%,
			rgba(255, 255, 255, 0.0) 100%);
}

.shiny__loading__bg:active:after {
	opacity: 0;
}

@keyframes shine {
	10% {
		opacity: 1;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
		transition-duration: 0.7s, 0.7s, 0.15s;
		transition-timing-function: ease;
	}

	100% {
		opacity: 0;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
	}
}

.rl-readonly {
	opacity: .5;
}

.bg-light {
	background: $gray-lightest !important;
}

.bg-light-blue {
	background: lighten($brand-secondary-aqua, 40%);
}

.rounded-panel {
	border-radius: $border-radius-base;
}
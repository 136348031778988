﻿/*---------------------------*\
* #NAVIGATION
\*---------------------------*/

.nav-link {
	color: $brand-highlight;
}

.nav-main {
	background-color: $brand-primary-medium-dark;
	color: $white;
	z-index: 2;

	.nav-link {
		transition: color 0.08s ease-in-out, background-color 0.08s ease-in-out, border-color 0.08s ease-in-out;
	}
}

/* Record/Section header navigation */

.nav__section,
.mod-details__nav nav {
	display: grid;
	grid-template-columns: 3fr auto;
	height: $mod-header-nav-height;
	background-color: $white;
	border-bottom: 1px solid $border-color-light;

	ul {
		margin: 0 0 0 8px;
	}

	li {
		display: inline-block;
		padding: 8px 0 0;
		margin-right: 10px;

		a {
			color: $jump-nav-link;
			font-size: $font-size-medium;

			&:hover,
			&:active {
				color: $brand-secondary-navy;
			}

			&.btn:focus {
				box-shadow: none;
			}
		}

		&.active {
			border-bottom: 2px solid $brand-success-medium;
			padding: 5px 0 1px 0;

			a {
				color: $jump-nav-link-active;
				font-weight: 700;
				padding: 0.2rem 0.75rem;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		li {
			padding: 9px 0 0;
			margin-right: 4px;

			a {
				font-size: $font-size-base;
			}
		}
	}
}


.report__nav.nav__section {
	li {
		padding: 0;

		a {
			padding: 10px 0;
			color: $jump-nav-link;
			font-size: $font-size-medium;

			&:hover {
				color: $brand-secondary-navy;
			}

			&.active {
				color: $jump-nav-link-active;
				font-weight: 700;
				border-bottom: 2px solid $brand-success-medium;
				padding-bottom: 8px;
			}

			span {
				padding: 0 0.5rem;
			}
		}
	}
}

/* Admin/Section header navigation */

.admin__nav .nav__section {
	display: inline-block;
	background-color: transparent;
	border-bottom: 0 none;

	li {
		padding: 0;

		button {
			color: $gray-darker;
			padding: 9px 0.5rem;

			&:hover,
			&:active {
				color: $brand-primary !important;
			}

			&.active {
				border-bottom: 2px solid $brand-success-medium;
				border-radius: 0;
				padding: 10px 0.5rem 8px;
				color: $gray-darker !important;
				font-weight: 700;
			}
		}

		.btn-link {
			color: $gray-darker;

			&:hover,
			&:active,
			&.active {
				color: $gray;
			}

			&:focus {
				box-shadow: none;
			}
		}
	}
}

nav .notices .btn {
	height: 25px;
	width: 25px;
	border-radius: 100%;
}

.component-dropdown-popover {
	left: -8px !important;
	min-width: 150px;
	border-radius: 0;

	.close-button {
		top: 3px;
		right: 1px;
	}

	.popover-header {
		margin: 0.5rem 0 0.5rem 1.15rem;
		margin-top: 0.5rem;
		background-color: transparent;
		border-bottom: none;
		font-size: $font-size-large;
		font-weight: 700;
		color: $brand-primary;
	}

	.popover-body {
		padding: 0;
	}

	.component-dropdown-link {
		display: block;
		padding: 3px 1.15rem;
		color: $gray;

		&:hover {
			color: $brand-primary;
			background: $gray-lighter;
		}
	}

	&::before {
		border-width: 5px 5px 5px 0;
		left: 3px;
		top: 3px;
	}

	.popover-arrow {
		position: absolute;
		top: 13.5px;
		left: -10px;
		width: 8px;
		height: 16px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 5px;
			height: 10px;
			left: 3px;
			top: 3px;
			border-color: transparent rgba(0, 0, 0, 0.176) transparent transparent;
			border-style: solid;
			border-width: 5px 5px 5px 0;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 5px;
			height: 10px;
			left: 4px;
			top: 3px;
			border-color: transparent $white transparent transparent;
			border-style: solid;
			border-width: 5px 5px 5px 0;
		}
	}
}
/*---------------------------*\
* #CONTENT
\*---------------------------*/

.content {
	overflow: auto;
	background-color: $gray-lighter;
}

.list__head {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: end;
	height: $page-head-height;
	padding: 10px 20px 0;
	background-color: $gray-lighter;

	h1 {
		color: $brand-primary;
	}

	div {
		justify-self: end;

		.btn .fa-plus {
			font-size: 1.675rem;
		}

		.dropdown-toggle::after {
			border: 0;
		}
	}
}

.list {
	padding: 10px 20px;

	/* this should be moved to grid scss files eventually */
	.rl-grid__title-bar {
		background-color: $white;
		height: 45px;
		border: 1px solid $border-color-light;
	}

	.rl-grid__title-bar__title {
		display: none;
	}

	.entity-search {
		padding: 1rem;
		border-radius: $border-radius-large;
	}
}
/*---------------------------*\
* #ACTIVITY POLICY
\*---------------------------*/

rl-activity-policy {
	.partial-allow.form-check-input:focus {
		border-color: $brand-success-medium !important;
		/* remove default box shadow */
		box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
	}

	/* background color when checked*/
	.partial-allow.form-check-input:checked {
		border-color: $brand-success-medium !important;
		background-color: $brand-success-medium !important;
	}

	/*background color of switch when active*/
	.partial-allow.form-check-input:active {
		background-color: $brand-success-medium !important;
		border-color: $brand-success-medium !important;
	}

	.partial-allow.form-check-input:focus {
		border-color: $brand-success-medium !important;
		/* remove default box shadow */
		box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
	}

	/*border color when not checked*/
	.form-check-input:focus:not(:checked) {
		border-color: $gray-medium-light !important;
	}

	// Set all switch in Record Access panel
	.mine-only.form-check {
		.form-check-input {
			border-color: $brand-primary-light !important;
		}

		.form-check-input:focus:not(:checked) {
			border-color: $brand-primary-light !important;
		}
	}
}
.rl-cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.rl-cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

.rl-cdk-overlay-container:empty {
    display: none
}
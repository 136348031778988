﻿/*---------------------------*\
* #TABLES
\*---------------------------*/

.table thead th {
	color: $gray-dark;
}

.grid--head--row {
	display: grid;
	grid-template-columns: 1fr 3fr repeat(2, 2fr);
	background-color: $table-head-bg;
	grid-auto-rows: 40px;
	column-gap: 12px;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;

	div {
		font-weight: 600;
	}
}

.grid--body--row {
	display: grid;
	grid-template-columns: 1fr 3fr repeat(2, 2fr);
	grid-auto-rows: 30px;
	column-gap: 12px;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;

	&:nth-of-type(odd) {
		background-color: $white;
	}

	&:nth-of-type(even) {
		background-color: $gray-lighter;
	}
}

.rl-grid__row-ops {
	background-color: $row-hover;
	height: 30px;
	pointer-events: auto !important;
	overflow-x: hidden;
	overflow-y: hidden;
	transition: width 0.33s ease-in-out;
	align-items: center;

	.btn {
		font-size: $font-size-base;
		margin: 2px 4px 0;
	}

	.hover-op-expand-btn {
		padding-left: 2px;
		padding-right: 2px;
		margin: 0;
		height: 100%;
		opacity: .25;
		width: 12px;

		i {
			color: $gray;
		}

		&:hover {
			opacity: 1;
			background-color: $gray-lighter;
		}
	}

	&--collapsed {
		width: 12px;

		.hover-op-expand-btn {
			opacity: 1;

			i:before {
				content: "\f053"
			}
		}
	}
}

.rl-grid__error-hover {
	background-color: $white;
	padding: 2px;
	border: 2px solid $state-danger-bg;
	display: block;
	transition: opacity 0.1s ease-in-out;

	.nav-btns {
		display: flex;
		justify-content: space-between;
		padding: 2px;
		background-color: $gray-lightest;
	}
}

.rl-grid__char-data-cell__panel {
	background-color: $white;
	padding: 2px;
	border: 2px solid $state-success-bg;
}

.rl-grid__char-data-column-edit__panel {
	background-color: $white;
	padding: 2px;
	border: 2px solid $state-info-bg;
	max-width: 390px;
}

.rl-grid__column-edit__panel {
	background-color: $white;
	padding: 10px;
	border: 2px solid $state-info-bg;
	max-width: 350px;
}

.rl-grid__header__cell__panel {
	min-width: 200px;
	background-color: $white;
	padding: 2px;

	@extend .shadow;
}

.rl-grid--edit-enabled {

	rl-grid-column-edit-cell,
	rl-grid-header-cell {
		border-right: 1px solid $gray-light;
	}

	.rl-grid__cell {
		border-right: 1px solid $gray-lighter;
	}
}

/* Basic Tables */

.rl-table {

	&.table-hover>tbody>tr:hover>*,
	&.table-hover>tbody>tr:hover>rl-account-group-rule>* {
		/* exception to allow hover in account group rules */
		background-color: $row-hover;
		--bs-table-accent-bg: none;
		--bs-table-bg-state: none;
		--bs-table-color-state: #none;
	}

	thead th {
		background-color: $table-head-bg;
		border-bottom: 0;
		vertical-align: middle;
		padding: 0.75rem;
		border-top: 1px solid $border-color;
	}

	td {
		padding: 0.35rem 0.75rem;
		border-bottom: 1px solid $gray-light;
	}

	.table__sub-head td {
		background-color: $table-head-bg-dark;
	}

	&.table-sm {
		thead th {
			padding: 0.4rem 0.75rem;
		}

		td {
			padding: 0.4rem 0.75rem;
		}
	}
}

.table-striped tbody {
	tr:nth-of-type(odd)>* {
		--bs-table-accent-bg: $white;
	}

	tr:nth-of-type(even) {
		background-color: rgba(0, 0, 0, .025);
	}

	td .form-control,
	th .form-control {
		background-color: transparent;
	}

	td .form-control.tree-list-control,
	th .form-control.tree-list-control {
		background-color: $white;
	}
}

.table> :not(:first-child) {
	border-top: 0;
}

.table-bordered> :not(caption)>* {
	border-width: 0;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
	border: 1px solid $border-color;
}

th .badge--select {
	font-weight: 500;
}

/* Table row hover icons */
.hover-icons {
	visibility: hidden;

	.btn,
	button {
		height: 22px;
	}
}

tr:hover .hover-icons {
	visibility: visible;
}

/* mini table - for mini instances */
.mini-table-container.scroll-vert {
	width: 350px;
	margin-top: 22px;
	max-height: 200px;

	&.showNotifyChild {
		width: 480px;

		th.col-title,
		td.col-title {
			width: 210px;
		}

		th.col-notify-child {
			width: 202px;
		}

		td.col-notify-child {
			width: auto;
		}

		.form-switch {
			top: -1px;

			label {
				top: 2px;
			}
		}
	}

	thead {
		position: fixed;
		top: 9px;
	}
}

.mini-table {
	th {
		background: $gray-lighter;
		font-size: $font-size-small;
		padding: 2px 3px;
		border: none;
		color: $gray-dark;

		&.col-id {
			width: 69px;
		}

		&.col-title {
			width: 175px;
		}

		&.col-members {
			width: 108px;
		}
	}

	td {
		font-size: $font-size-small;
		padding: 2px 3px;
		border: none;
		color: $gray;

		&.col-id {
			width: 69px;
		}

		&.col-title {
			width: 175px;
		}

		&.col-members {
			width: auto;
		}
	}
}

/* Table No Items */
.no-items-message {
	color: $gray-medium-lighter;

	&:hover>* {
		--bs-table-accent-bg: inherit !important;
		color: inherit !important;
		background-color: inherit !important;
	}

	i {
		color: $gray-light;
		font-size: 30px;
	}

	&:not(:last-child) {
		display: none;
	}
}

.rl-table.table-hover>tbody>tr.no-items-message:hover>* {
	background-color: inherit;
	color: inherit;
}
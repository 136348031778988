﻿/*---------------------------*\
* #DROPDOWNS
\*---------------------------*/

$dropdown-bg: $white;
$dropdown-border: rgba(0, 0, 0, .15);
$dropdown-link-color: $gray-dark;
$dropdown-link-hover-color: $link-color;
$dropdown-link-hover-bg: $row-hover;

// Dropdown menu 'border-color' **for IE8**.
$dropdown-fallback-border: $border-color;

// Divider color for between dropdown items.
$dropdown-divider-bg: $border-color-light;

// Active dropdown menu item
$dropdown-link-active-color: $link-color;
$dropdown-link-active-bg: $row-hover;

// Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-medium;

// Text color for headers within dropdown menus.
$dropdown-header-color: $gray-medium;


.dropdown-toggle::after,
.dropup .dropdown-toggle::after {
	border: 0 none;
	font-family: 'Font Awesome 5 Pro';
	font-size: 0.9em;
	content: '\F107';
	vertical-align: 0;
	margin-left: 10px;
}

.dropdown-toggle::after {
	transition: all 0.5s ease;
}

.show>.dropdown-toggle::after {
	transform: rotate(180deg);
}



.dropdown-menu {
	font-size: $font-size-base;
	border-radius: 0;
	/*setting this globally to ensure menus aren't too long*/
	max-height: 350px;
	overflow: auto;
	z-index: 1060;

	a {
		cursor: pointer;
	}

	/* Remove padding on dropdowns that have tabs */
	.tab-content {
		padding: 0;
	}
}

.hide-caret.dropdown-toggle::after {
	display: none;
}

.menu-pane {
	width: 400px;
	padding: 0;
}

.menu-pane__header {
	color: $brand-primary;
	background-color: $gray-more-lightest;
	padding: 0.5em 1em;
	min-height: 45px;

	&>.btn-icon i,
	&>.btn-link {
		color: $brand-primary;
	}

	h5 {
		font-weight: 600;
	}
}

.menu-pane__body {
	padding-top: 0.5rem;
	max-height: 250px;
	overflow: auto;
}

.dropdown-header {
	color: $gray-medium;
	font-size: $font-size-small;
	text-transform: uppercase;
	margin: 0;
	padding: 0.5em;
}


.list-group-flush .list-group-item {
	color: $gray;
	font-family: $font-family-base;
	text-align: left;
	padding: 0.25rem 0.5rem;
	border: 0 none;

	&:hover {
		color: $brand-highlight;
		background-color: $row-hover;
	}
}

.list-group-flush .list-group-item.disabled,
.list-group-flush .list-group-item:disabled {
	color: $gray-medium-light;
}

/* DROPDOWN MENUS WITH ACTIONS */
.list-group-item-action {
	background: inherit;
}

.list-group--menu,
.panel__ops .list-group--menu {
	.list-group-item {
		padding: 0.125rem 0.5rem;
	}

	.list-group-item__actions {
		opacity: 0;
		text-align: right;

		& i {
			font-size: 0.75rem;
			padding-left: 0.5em;
		}
	}

	.list-group-item:hover .list-group-item__actions {
		opacity: 1;
	}

	.list-group-item__active {
		opacity: 0;
	}

	.list-group-item.active,
	.list-group-item.active .list-group-item-action {
		color: $brand-primary;
		background-color: transparent;

		&:hover {
			background-color: $dropdown-link-hover-bg;
		}

		.list-group-item__active {
			opacity: 1;
		}
	}
}


/* DROPDOWN MENUS with TABS */

.dropdown-menu .tab-content .dropdown-header {
	padding: 0.5rem;
	margin-top: 0.75rem;
}

.dropdown-item {
	&:visited {
		color: $gray;
	}

	&.disabled {
		color: $gray-medium-light !important;
	}
}

.dropdown-menu .nav-tabs {
	i {
		color: $gray-medium-light;
	}

	.nav-link.active i,
	.nav-link:hover i {
		color: $brand-primary;
	}
}


/* DROPDOWN with TREELIST */
.rl-grid__char-data-column-edit__panel .tree-list-control.form-control {
	border: 0 none;
}

.rl-grid__keyart-cell__panel {
	display: inline-block;
	background-color: $white;
	padding: 5px;
	border: 2px solid $state-info-bg;
	min-width: 150px;
	max-width: 330px;

	img {
		max-height: 300px;
		max-width: 300px;
	}
}

.rl-grid__json-cell__panel {
	display: inline-block;
	background-color: $white;
	padding: 5px;
	border: 2px solid $state-info-bg;
	min-width: 200px;
	max-width: 450px;
	max-height: 450px;
	overflow: auto;
}

.rl-grid__text-large-cell__panel {
	display: inline-block;
	background-color: $white;
	padding: 5px;
	border: 2px solid $state-info-bg;
	min-width: 200px;
	max-width: 450px;
	max-height: 450px;
	overflow: auto;
}
/*---------------------------*\
* #BASE
\*---------------------------*/

.no-record {
	i {
		font-size: 20px;
		color: $gray-medium-light;
	}

	h5,
	p {
		font-weight: 300;
		color: $gray-medium;
	}
}

/* wrapper to scroll content, in order to keep buttons in view, etc. */
.scroll-wrapper-vertical {
	height: 100%;
	/*need to make the subtractions dynamic (header height, footer height, etc)*/
	max-height: calc(100vh - 320px);
	overflow: auto;
}

.dashboard .jump-to-page {
	display: none;
}
﻿/*---------------------------*\
* #DATE MATH
\*---------------------------*/

.datemath__edit-row__options-popover.popover {
    min-width: 40rem;
    max-width: 750px;

    @include media-breakpoint-down(md) {
        min-width: 17rem;
        max-width: 22rem;

        .datemath__edit-row__options span {
            display: block;
            margin-top: 15px;
        }
    }
}
﻿/*---------------------------*\
* #BADGES
\*---------------------------*/

.badge-notice {
    color: $gray;
    background-color: $brand-warning-lighter;
    padding: 0.25rem .5rem;
}

.badge--select {
    background-color: $gray-lighter;
    border-radius: 15px;
    padding: 0.125rem 0.75rem 0.125rem 0.25rem;
    margin: 0.25rem;

    .btn-remove {
        margin-right: 0.5rem;
        padding: 0;
    }
}
/*---------------------------*\
* #ICON ALERT
\*---------------------------*/

i.icon-alert-warning::after,
.icon-alert-warning i::after,
i.icon-alert-danger::after,
.icon-alert-danger i::after {
	content: '';
	display: block;
	position: absolute;
	top: 2px;
	right: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	box-shadow: 1px 1px 3px $gray-medium-light;
	z-index: 1;
}

i.icon-alert-warning::after,
.icon-alert-warning i::after {
	background-color: $brand-warning;
}

i.icon-alert-danger::after,
.icon-alert-danger i::after {
	background-color: $brand-danger;
}
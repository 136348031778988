﻿/*---------------------------*\
* #MODALS
\*---------------------------*/

.modal-content {
	padding: 0 2rem;
}

.modal-header {
	justify-content: space-between;
}

.modal-header .btn-close {
	margin: 0;
	padding: 0.375rem 0.75rem;
	font-size: 1.25rem;
}
﻿/*---------------------------*\
* #POPOVERS
\*---------------------------*/

.help {
	.fa-question-circle {
		color: $brand-primary;
		cursor: pointer;
	}
}

.popover {
	min-width: 17rem;
	max-width: 26rem;
	box-shadow: rgba(0, 0, 0, .15) 0 10px 10px;
	font-family: $font-family-base;

	&.bs-popover-bottom-right {
		margin-left: 29px;
		/* aligns the arrow under the icon */
	}

	&.bs-popover-bottom-left {
		margin-left: -20px;
		/* aligns the arrow under the icon */
	}

	.popover-body {
		color: $gray;
	}
}

.rl-grid__row-ops .popover {
	min-width: 10rem;
	max-width: 20rem;
}

.popover-body .tree-list-control.form-control {
	border: 0;
	padding: 0;
}

.audit-history-report-popover-window-override {
	width: 280px;
	z-index: 1000 !important;
}
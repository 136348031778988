﻿/*---------------------------*\
* #BOOTSTRAP OVERRIDES
\*---------------------------*/


/* MISC */
.badge-light {
	color: $gray;
}

.card {
	border-radius: $border-radius-base;
	border: 0;
	overflow: hidden;

	.panel & {
		border: 1px solid $border-color;
	}
}

.modal-content {
	border-radius: 0;
	border: 0;
}


/* DROPDOWNS */
.dropdown-item {
	color: $gray;
	font-weight: 500;
	padding: 0.25rem 0.5rem;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
	color: $brand-highlight;
	text-decoration: none;
	background-color: $row-hover;
}


/* FORMS */
/* make input height same as button height in form-groups */
/* Still working on finding the perfect all-encompassing styling for all input group uses.
	align-items end will hopefully offer support for btn and btn-sm icon instances */
.input-group {
	align-items: end;
}

.input-group>.form-control {
	height: 29px;
}

.form-control {
	font-size: $font-size-base;
	border-radius: 0;
	padding: 0.125rem 0.5rem;
	height: calc(2em + 3px);

	&:focus {
		color: $gray;
		border-color: $brand-primary;
		box-shadow: none;
	}

	&.custom-select {
		padding-right: 1.75rem;
	}
}


/* ACCORDION */
.accordion {
	.card-header:first-child {
		border-radius: 0;
	}

	.card:last-of-type {
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	}

	.card-header {
		padding: .5rem .75rem;
	}

	.card-header>button,
	.card-header>.panel__title {
		font-size: $font-size-large;
		font-weight: 600;
		padding: 0;
		width: 100%;
		color: $gray;
		text-align: left;

		&.collapsed {
			color: $brand-highlight;
		}
	}

	.card .card-header:last-child {
		margin-bottom: 0;
	}
}


/* PAGINATION */
.pagination {
	margin: .25rem 0;

	.page-link {
		padding: 0.25rem 0.5rem;
		line-height: 1;
		background-color: transparent;
		border: 0 none;
	}

	.page-item.active .page-link {
		font-weight: 700;
		color: $black;
		background-color: transparent;
	}

	.page-item.disabled .page-link {
		color: $gray-medium-light;
		background-color: transparent;
		border-color: transparent;
	}

	.page-link[aria-label="First"] {
		span {
			display: none;
		}

		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\F100';
		}
	}

	.page-link[aria-label="Previous"] {
		span {
			display: none;
		}

		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\F104';
		}
	}

	.page-link[aria-label="Next"] {
		span {
			display: none;
		}

		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\F105';
		}
	}

	.page-link[aria-label="Last"] {
		span {
			display: none;
		}

		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\F101';
		}
	}
}


/* MODAL */
.modal-backdrop {
	background-color: $white;
}

.modal-dialog {
	width: 100%;
	max-width: 100%;
	margin: 0 auto 2rem;
	box-shadow: 0 10px 12px rgba(0, 0, 0, .15);
}

.modal-content {
	border: 0 none;
}

.modal-body {
	padding: 2rem;
}


/* Datepicker */
.dropdown-menu.ngb-dp-body {
	z-index: 2100;
}

.ngb-dp-today {
	background-color: $gray-medium-lighter;
	border-radius: 5px;
}

/* Alert
	If we start implementing the other alerts across the site, the overrides should be added here as well
*/
.alert {
	border-radius: $border-radius-base;
}

.alert-success {
	color: $gray;
	background-color: $brand-success-light;
	border: 1px solid $brand-success-medium;
}

.alert-warning {
	color: $gray;
	background-color: $brand-warning-lighter;
	border: 1px solid darken($brand-warning-light, 15%);
}

.alert-danger {
	color: $gray;
	background-color: $brand-danger-lighter;
	border: 1px solid darken($brand-danger-lighter, 15%);
}

.alert-info {
	color: $gray;
	background-color: $gray-lighter;
	border: none;
}

.alert-sm {
	padding: 0.5rem 1rem;
	border: none;
}
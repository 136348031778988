﻿/*---------------------------*\
* #CHAR-DATA
\*---------------------------*/

.char-data__group {
	margin-bottom: 15px;
}

.char-data__group__element {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.char-data__label {
	background-color: $table-head-bg;
	text-align: right;
	padding: 4px 20px 4px 8px;
	font-weight: 600;

	&.col-form-label {
		padding-top: calc(0.375rem + 2px);
	}
}

.char-data__row-ops {
	text-align: right;
	display: none;

	.btn {
		font-size: $font-size-base;
		padding: 0 5px;
		line-height: 1;
	}
}

.char-data__control {

	&.form-group {
		margin-bottom: 0;
	}

	&>div {
		padding: 4px 8px;

		&:hover {
			background-color: $row-hover;

			.char-data__row-ops {
				display: inline-block;
			}
		}
	}
}

.char-data__group__element-compare {
	grid-template-columns: 220px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

	&.char-data__group__element-header {
		background-color: rgba(246, 246, 246, .8); //$gray-light / 50% opacity
		position: sticky;
		top: 0;
		z-index: 100;

		.header-label {
			border-left: 1px solid $gray-medium-lighter;
		}
	}

	.extracted {
		background-color: $gray-more-lightest;
	}

	&:hover .char-data__control {
		background-color: $gray-more-lightest;
	}
}

.char-data-element-edit {
	.form-group {
		margin-bottom: 0;
	}

	.form-check.form-check-inline {
		margin: 0.25rem 0 0;
	}

	&.-display .form-switch {
		margin-top: 2px;
	}
}

$grid-cell-height: 30px;

/** Grid overrides **/
.char-data__element--grid {
	.grid-hide {
		display: none;
	}

	rl-text-display-control span {
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-height: 1.5em;
	}

	rl-text-edit-control,
	rl-timecode-edit-element,
	rl-number-edit-element,
	rl-percentage-edit-element,
	rl-year-edit-element,
	rl-amount-edit-element {
		input[type=text] {
			padding: 0;
			height: $grid-cell-height;

			.rl-grid__cell--focus & {
				background-color: $state-success-bg;
			}

			border-bottom: unset;
		}
	}

	rl-dropdown-edit-control {
		select.form-select {
			padding: 0;

			.rl-grid__cell--focus & {
				background-color: $state-success-bg;

				option {
					background-color: $white;
				}
			}

		}
	}
}

.char-data__element--column-edit {
	rl-text-display-control span {
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-height: 1.5em;
	}
}

.char-data__element--grid,
.rl-grid__char-data-cell__panel {

	rl-dropdown-edit-control {
		select.form-select {
			option {
				padding: 0;
			}

			border-bottom: unset;
		}

		select.form-select:not([multiple=true]) {
			height: $grid-cell-height;
		}
	}
}

.rl-grid__char-data-cell__panel {
	.grid-hide.grid-panel-show {
		display: block;
	}
}

.cdk-overlay-container {
	z-index: 1070;
}

.date-option__panel {
	background-color: $white;
	z-index: 2000;
	width: 95%;

	@include media-breakpoint-up(md) {
		width: 80%;
	}

	@include media-breakpoint-up(lg) {
		width: 60%;
	}
}

/* break long links to new line */
.char-data__control .link-display {
	word-break: break-all;
}

.char-data__visualNotice {
	display: block;
	position: absolute;
	top: 9px;
	right: 13px;
	width: auto;

	.has-error & {
		color: $brand-danger;
	}

	.has-warning & {
		color: $brand-warning;
	}
}
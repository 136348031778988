.admin {
	position: absolute;
	width: calc(100% - $side-nav-compact);
	height: calc(100vh - $page-head-height - $mod-header-nav-height - $footer-height);
	margin-top: $mod-header-nav-height;
	top: $page-head-height;
	overflow: auto;
	background: $white;
}

.is-util-landing .admin {
	background: none;
}

.admin__header {
	position: fixed;
	width: calc(100% - $side-nav-compact);
	height: $mod-header-nav-height;
	top: $page-head-height;
	color: $gray-darker;
	padding: 0 16px;
	z-index: 10000;

	h1 {
		margin: 0.325rem 0 0;
	}

	h1 a,
	a {
		color: $gray-darker;

		&:hover {
			color: $gray;
			opacity: .8;
		}
	}

	.active i {
		opacity: 0.6;
	}
}

@include media-breakpoint-down(md) {
	.admin__header h1 {
		font-size: $font-size-h4;
	}
}

.admin__nav {
	height: $mod-header-nav-height;
}

.admin__body {
	height: calc(100vh - 90px - $footer-height);
	outline: none;

	/* ADMIN/CONFIG DASHBOARDS */
	.card-title {
		color: $brand-primary;
	}

	.card-subtitle {
		display: block;
		margin-bottom: 0.5rem;
		font-size: $font-size-h4;
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		line-height: 1.7;
	}

	.list-group-flush .list-group-item {
		border-bottom: 1px solid $border-color-light;

		&:last-child {
			border-bottom: none;
		}

		a,
		div.d-flex {
			padding: 1rem 0.25rem;
			color: $gray;

			i {
				color: $brand-primary-medium;
				opacity: 0.75;
				margin: 0.25rem 0.5rem 0 0;
				min-width: 24px;
			}
		}

		a:hover,
		a:active,
		a:focus {
			color: $brand-secondary-navy;
		}

		&:hover {
			background-color: $gray-lightest;

			a i,
			div.d-flex i {
				opacity: 1;
				color: $brand-primary;
			}
		}
	}

	/* ADMIN/CONFIG PANEL HEADER */
	.header-grid {
		display: grid;
		grid-auto-flow: column;
		align-items: stretch;
		background-color: $gray-lightest;
		border-radius: $border-radius-large;

		>*:not(:last-child) {
			border-right: 1px solid $border-color;
		}

		.record__summary {
			padding: 1rem 0.5rem 0;
			min-width: 80px;

			.value.dropdown-toggle {
				cursor: pointer;
			}
		}
	}
}

.admin-footer {
	position: fixed;
	bottom: 0;
	width: calc(100% - 50px);
	right: 0;
}
/*---------------------------*\
* #BUTTONS
\*---------------------------*/

$btn-font-size: $font-size-h6; //15px
$btn-font-size-sm: $font-size-base; //13px
$btn-font-weight: $headings-font-weight;

$btn-light-color: $gray-darker;
$btn-light-bg: $default-button-bg;

$btn-primary-color: $white;
$btn-primary-bg: $brand-primary;

$btn-success-color: $white;
$btn-success-bg: $brand-success;

$btn-info-color: $white;
$btn-info-bg: $brand-info;

$btn-warning-color: $white;
$btn-warning-bg: $brand-warning;
$btn-warning-bg-hover: $brand-warning-dark;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-bg-hover: $brand-danger-dark;

$btn-link-disabled-color: $gray-medium;

button {
	font-weight: 500;
}

.btn {
	padding: 0.42rem 1.75rem;
	border-radius: $border-radius-small;
	border-width: 0;
	font-size: $btn-font-size;
	font-family: $headings-font-family;
	font-weight: 500;
	white-space: nowrap;

	&:active:focus,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus {
		box-shadow: none;
	}
}

.btn-sm,
.btn-xs {
	padding: 0.32rem 1.25rem;
	border-radius: $border-radius-x-small;
	font-size: $btn-font-size-sm;
}

.btn.btn-link {
	padding: 0.35rem 0.75rem;
}

.btn-reset-padding {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.input-group-append {
	.btn {
		border-width: $btn-border-width;
		border-style: solid;
	}
}

.btn-success {
	color: $white;
	background: $brand-success;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $white;
		background: $brand-success-dark;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $white;
		background: $brand-success;
		opacity: .5;
	}
}

.btn-info {
	color: $btn-info-color;
	background: $brand-primary;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $btn-info-color;
		background: $brand-primary-medium-dark;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $btn-info-color;
		background: $brand-primary-medium-dark;
		opacity: .6;
	}

	.show>&.dropdown-toggle {
		color: $btn-info-color;
		background: $brand-primary;
		border-color: transparent;
	}
}

.btn-light {
	color: $btn-light-color;
	background: $btn-light-bg;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $btn-light-color;
		background: $gray-medium-lighter;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $btn-light-color;
		background: $gray-lightest;
		opacity: 1;
	}
}

.btn-highlight {
	color: $brand-primary-medium-dark;
	background: $brand-primary-light;
	font-weight: 600;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $brand-primary-medium-dark;
		background: darken($brand-primary-light, 5%);
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $gray-medium-light;
		background: lighten($brand-primary-light, 5%);
		opacity: 1;
	}
}

.btn-warning {
	color: $btn-warning-color;
	background-color: $btn-warning-bg;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show>&.dropdown-toggle {
		color: $white;
		background-color: $btn-warning-bg-hover;
		box-shadow: none;

		i {
			color: $btn-warning-color;
		}
	}
}

.btn-outline-warning:hover {
	color: $btn-warning-bg-hover;
	background-color: transparent;
	border-color: transparent;
}

.btn-outline-danger:hover {
	color: $btn-danger-bg-hover;
	background-color: transparent;
	border-color: transparent;
}


/* ICON BUTTONS */

.btn-icon {
	padding: 2px 4px;
	background-color: transparent;
	background-image: none;
	border: 0 none;

	&.btn-sm {
		height: 28px;
	}

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-image: none;
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
	}

	&:disabled {
		background-image: none;
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
	}

	i {
		color: $gray-medium-light;
		line-height: 1;
	}

	&.btn-success:hover i,
	&.btn-success:active i {
		color: $brand-success-dark;
	}

	.show>&.btn-success.dropdown-toggle {
		color: $brand-success;
		background: transparent;
		border-color: transparent;

		& i,
		&:hover i {
			color: $brand-success;
		}

		&:focus {
			box-shadow: none;
		}
	}

	&.btn-info:hover i,
	&.btn-info:active i {
		color: $brand-primary;
	}

	.show>&.btn-info.dropdown-toggle {
		color: $brand-primary;
		background: transparent;
		border-color: transparent;

		& i,
		&:hover i {
			color: $brand-primary;
		}

		&:focus {
			box-shadow: none;
		}
	}

	&.btn-warning i {
		color: $btn-warning-bg;
	}

	&.btn-warning:hover i,
	&.btn-warning:active i {
		color: $btn-warning-bg-hover !important;
	}

	&.btn-danger i {
		color: $btn-danger-bg;
	}

	&.btn-danger:hover i,
	&.btn-danger:active i {
		color: $btn-danger-bg-hover;
	}

	&.border-bottom {
		border-bottom: 1px solid $border-color !important;
		border-radius: 0;
		/* need specificity to override bootstrap */
	}

	&.pendo-tip {
		i {
			font-size: 0.825rem;
		}
	}

	&.btn-utils {
		i {
			font-size: 1.1rem;
		}
	}

}

/* Reset bootstrap 5 btn-close styling */
.btn-close {
	box-sizing: unset;
	width: unset;
	height: unset;
	padding: 0;
	color: unset;
	background: none;
	border-radius: 0;
	opacity: 1;
}

.btn-close,
.btn-remove {

	i {
		color: $gray-medium-light;
		padding: 0.125rem;
	}

	&:hover i,
	&:active i,
	&:focus i,
	&:not(:disabled):not(.disabled):active i,
	&:not(:disabled):not(.disabled).active i {
		color: $brand-danger;
		box-shadow: none;
	}
}

.btn-link {
	color: $brand-highlight;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $brand-secondary-navy;
		text-decoration: none;
	}
}

.show-hover {
	opacity: 0;
	transition: opacity 0.5s linear;

	&:hover {
		opacity: 1;
		transition: opacity 0.25s linear;
	}
}



/* Keep at the bottom to capture all button variations */
.btn-icon:disabled:hover i {
	-webkit-text-fill-color: $gray-medium-light;
}

.panel__ops .btn-icon.btn-success i,
.list__head .btn-success i {
	color: $brand-success;
}


/* stops the ugly blue border that Chrome adds to buttons */
/* TODO: Implement a more visually appealing method for showing focus (For Katie) */
button:focus {
	outline: none !important;
	/* From Chrome */
	box-shadow: none !important;
	/* From Bootstrap */
}

.input-group-prepend,
.input-group-append {
	display: flex;
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text,
.input-group-prepend .btn,
.input-group-append .btn {
	padding-top: 0;
	padding-bottom: 0;
	border: none;
}

.input-group-text {
	display: block;
}
﻿/*---------------------------*\
* #DRAGGABLE
\*---------------------------*/

.drag__handle {
	text-align: center;
	padding: 0 4px;
	color: $brand-primary-medium;
	margin-right: 0.25rem !important;
	/* specificity to override dynamic element settings */

	&:hover {
		cursor: grab !important;
	}

	&:active {
		cursor: grabbing !important;
	}
}

.cdk-drop-list-dragging:hover {
	cursor: grabbing;
}

.cdk-drop-list-dragging .cdk-drag {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
	opacity: 0;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
		0 3px 14px 2px rgba(0, 0, 0, 0.12);
	padding: 4px;
}
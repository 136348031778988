﻿/*---------------------------*\
* #SCROLL
\*---------------------------*/

.scroll-vert {
	overflow-y: auto;
	/* Add max-height settings per instance, eg: max-height: calc(100vh - 350px); */
}

.scroll-vert::-webkit-scrollbar {
	width: 10px;
	text-align: center;
}

.scroll-vert::-webkit-scrollbar-track {
	background-color: $gray-lightest;
}

.scroll-vert::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.125);
}
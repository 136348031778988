/*-----------------------------------------*\
* #GLOBAL RIGHTSLINE VARIABLES
\*-----------------------------------------*/

// Set Font Awesome font path
$fa-font-path: "@fortawesome/fontawesome-pro/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suit your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss


/*---------------------------*\
* #BRAND-COLORS
\*---------------------------*/

// Grayscale
$black: #000;
$gray-darker: #282828;
$gray-dark: #333;
$gray: #555;
$gray-medium: #777;
$gray-medium-light: #999;
$gray-medium-lighter: #ccc;
$gray-light: #eee;
$gray-lighter: #f2f2f2;
$gray-lightest: #f5f5f5;
$gray-more-lightest: #f9f9f9;
$white: #fff;

// Brand NEW
$brand-primary: #4F3099;
$brand-primary-light: #ECEAF9;
$brand-primary-medium: #A297E0;
$brand-primary-medium-dark: #3B2473;
$brand-primary-dark: #1E162B;
$brand-secondary-aqua: #1DAEFF;
$brand-secondary-aqua-dark: #0072be;
$brand-secondary-navy: #3A40B6;
$brand-secondary-turquoise: #3CBCAF;
$brand-success: #40a458;
$brand-success-light: #EFF9F2;
$brand-success-medium: #A3DDB0;
$brand-success-dark: #327d43;
$brand-warning: #F90;
$brand-warning-lighter: #FFFBF1;
$brand-warning-light: #FEF3D8;
$brand-warning-medium: #FFCC4D;
$brand-warning-dark: #FF7E00;
$brand-danger: #FF5050;
$brand-danger-lighter: #FEEDED;
$brand-danger-light: #FFDCDC;
$brand-danger-medium: #FF9696;
$brand-danger-dark: #DB2E2E;
$brand-info: $brand-secondary-aqua-dark;
$brand-highlight: $brand-secondary-aqua-dark;
$universal-search-bg: #584688; // 15% opacity $brand-primary-medium-dark


// Scaffolding
$body-bg: $white;
$text-color: $gray;
$content-bg: $white;
$sub-panel-bg: $gray-more-lightest;
$border-color: #ddd;
$border-color-light: $gray-light;
$border-color-dark: $gray-medium-lighter;
$row-hover: $gray-more-lightest;
$jump-nav-link: #808080;
$jump-nav-link-active: $gray-darker;
$default-button-bg: #ebebeb;

/*---------------------------*\
* #STRUCTURE
\*---------------------------*/

$page-head-height: 45px;
$mod-header-height: 40px;
$mod-header-nav-height: 40px;
$total-mod-header-height: $mod-header-height+$mod-header-nav-height;
$side-nav-width: 140px;
$side-nav-compact: 50px;
$footer-height: 28px;


/*---------------------------*\
* #TYPOGRAPHY
\*---------------------------*/

$font-family-sans-serif: 'Plus Jakarta Sans',
	sans-serif;
$font-family-serif: Georgia,
	'Times New Roman',
	Times,
	serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: 'Plus Jakarta Sans',
	Helvetica,
	Arial,
	sans-serif;
$headings-font-weight-strong: 700;
$headings-font-weight: 600;
$headings-line-height: 1.1;
$headings-color: inherit;

// Default monospace fonts for '<code>', '<kbd>', and '<pre>'
$font-family-monospace: Menlo,
	Monaco,
	Consolas,
	'Courier New',
	monospace;

$font-size-base: 12.5px;
$font-size-tiny: 10.5px;
$font-size-small: 11.5px;
$font-size-medium: 13px;
$font-size-large: 15px;
$font-size-larger: 16px;

$font-size-h1: 22px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 15px;
$font-size-h6: 14px;

$font-size-record-title: $font-size-h1; //22px
$font-size-panel-title: $font-size-h2; //20x
$font-size-panel-subtitle: $font-size-larger; //16px

// Unit-less 'line-height' for use in components like buttons.
$line-height-base: 1.6; // 20/12.5
$line-height-large: 1.92; // 24/12.5
$line-height-small: 1.12; // 14/12.5

// Computed 'line-height' ('font-size' * 'line-height') for use with 'margin', 'padding', etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px


/*---------------------------*\
* #MISC
\*---------------------------*/

$border-radius-base: 10px; //small containers
$border-radius-large: 12px; //panels and main content
$border-radius-small: 7px; //buttons
$border-radius-x-small: 4px; //buttons sm/xs


/*---------------------------*\
* #TABLES
\*---------------------------*/
// Customizes the '.table' component with basic values, each used across all table variations.

$table-head-bg: $brand-primary-light;
$table-head-bg-dark: darken($brand-primary-light, 5%); //#dad6f3
$table-head-bg-gray: #e0e0e0;


/*---------------------------*\
* #FEEDBACK AND ALERTS
\*---------------------------*/

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: 700;

$state-success-text: $gray;
$state-success-bg: $brand-success-light;

$state-info-text: $gray;
$state-info-bg: $brand-primary-light;

$state-warning-text: $gray;
$state-warning-bg: $brand-warning-lighter;

$state-danger-text: $brand-danger-dark;
$state-danger-bg: $brand-danger-lighter;
$state-danger-border: $brand-danger-light;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
﻿/*---------------------------*\
* #TABS
\*---------------------------*/

.tab-content {
	background-color: $white;
	padding: 15px;
}

.tabset--rl {
	&.nav-tabs {
		.nav-link {
			font-size: $font-size-large;
			border-radius: 0;
			padding: 0.75rem 1.5rem;
		}

		.nav-link.active,
		.nav-item.show .nav-link {
			color: $gray;
		}
	}

	.tab-content {
		padding: 0;
	}
}


/* large tabs with grey background */
.tabset--rl.grey {
	&.nav-tabs {
		border-bottom: 0 none;
		background-color: $gray-lighter;
		padding: 0.5rem 0.5rem 0;

		.nav-link {
			border: 0 none;
		}

		.nav-link.active,
		.nav-item.show .nav-link {
			background-color: $white;
		}
	}
}

/* large square tabs */
.tabset--lg {
	ul {
		padding-left: 15px;
		padding-right: 15px;
	}

	.nav-tabs .nav-link {
		border-radius: 6px 6px 0 0;
		padding: 0.675rem 1rem;
		font-size: $font-size-medium;
		font-family: $headings-font-family;
	}

	/* for regular tabs nested inside large tabs */
	.tab-pane {
		.nav-tabs .nav-link {
			border-top-left-radius: 0.25rem;
			border-top-right-radius: 0.25rem;
			padding: 0.5rem 1rem;
			font-size: $font-size-base;
			font-family: $font-family-base;
		}
	}
}

/* left aligned tabset */
.tabset--left {
	align-self: stretch;
	padding-right: 15px;
	padding-top: 15px;

	.nav-tabs {
		display: block;
		width: 275px;
		height: 100%;
		min-height: 550px;
		background: none;
		border: none;
		border-top: 1px solid $border-color;
	}

	.nav-link {
		display: block;
		margin-bottom: 0;
		padding: 0 0 0 10px;
		font-size: 13.5px;
		line-height: 55px;
		color: $gray;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid $border-color;
		cursor: pointer;

		&:hover {
			background: $gray-lighter;
			color: $brand-primary;
		}

		&.active {
			position: relative;
			padding-right: 20px;
			color: $brand-primary;
			border-bottom: 1px solid $border-color;

			&::after {
				content: '\f054';
				display: block;
				position: absolute;
				top: 1px;
				right: 7px;
				font-family: 'Font Awesome 5 Pro';
				font-size: 14px;
				font-weight: 800;
				color: $brand-primary;
			}
		}
	}
}
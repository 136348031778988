﻿.izenda--wrapper {
	.izenda-TopMenu-logo {
		display: none !important;
	}

	.izenda-UserMenu-Info a.dropdown-toggle::before {
		content: 'Settings';
	}

	.izenda-UserMenu-Info a.dropdown-toggle span.izenda-UserMenu-Username {
		display: none !important;
	}

	.izenda-NotificationBoard {
		display: none !important;
	}

	.izenda-UserMenu-Bell {
		display: none !important;
	}

	.izenda-Footer-copyright {
		display: none !important;
	}

	div.izenda-root.izenda-container .izenda-Footer {
		display: none !important;
	}

	button.btn-addFilter {
		display: none !important;
	}

	div.izenda-root.izenda-container .izenda-Footer .izenda-Footer-copyright {
		display: none !important;
	}

	//Overwrite bootstrap class here.
	.nav {
		display: block;
	}
}

div.izenda-root.izenda-container .izenda .izenda-Main.unfilledLayout {
	top: 5px;

	.izenda-MainContent {
		box-shadow: none;
		background-color: transparent;

		&>.izenda-Wrapper {
			border-radius: 10px;
			box-shadow: none;
			padding: 0;
			margin: 0;
		}
	}
}
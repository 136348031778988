﻿/*---------------------------*\
* #SIDEPANEL
\*---------------------------*/

.side-panel {
	.side-panel__body {
		display: flex;

		.tab-content {
			width: 100%;
			min-width: 400px;

			&>.tab-pane {
				height: 100%;
			}
		}

		&.collapse {
			.tab-content {
				display: none;
			}
		}

		h3 {
			display: inline-block;
			font-weight: 500;
		}
	}

	.nav-tabs {
		border: 0 none;
		background-color: $gray-lightest;
		height: calc(100vh - 125px);
		position: sticky;
		top: 0;
		z-index: 1020;

		.nav-link {
			color: $gray-medium-light;
			border: 0 none;
			padding: 1rem .75rem;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			cursor: pointer;

			i {
				font-size: $font-size-large;
			}

			&:hover {
				color: $brand-primary-medium;
			}
		}

		.nav-link.active,
		.nav-item.show .nav-link {
			border: 0;

			i {
				color: $brand-primary;
				background: -webkit-linear-gradient($brand-primary-medium, $brand-primary);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.nav-item {
			text-align: center;
		}
	}
}


/* HISTORY */
.history__filter--date {
	.form-control {
		max-width: 110px;
	}
}

.history__list .accordion .accordion-item {
	.accordion-header {
		background-color: $white;
		padding: 8px 12px;

		button {
			width: 100%;
			font-size: $font-size-small;
			font-family: $font-family-base;
			font-weight: 300;
			text-align: left;

			&.collapsed.btn-link {
				color: $gray;
			}
		}

		.timestamp {
			font-size: $font-size-tiny;
			color: $gray-medium-light;
		}
	}

	.accordion-button {
		padding: 0;
	}

	.accordion-body {
		margin-top: -1px;
		padding-bottom: 0;
		background-color: $white;
	}
}


.history__item {
	padding-left: 0.5rem;
	font-size: $font-size-small;

	&.danger {
		border-left: 3px solid $brand-danger;
	}

	&.success {
		border-left: 3px solid $brand-success;
	}

	&.warning {
		border-left: 3px solid $brand-warning;
	}

}

.history__detail {
	.char-data__control {

		.new-value,
		.old-value {
			/* for things like urls */
			word-break: break-word;
		}
	}
}

/* PREVIEWS */

.record-preview {
	.char-data .char-data__group:last-child {
		margin-bottom: 0;
	}
}

.side-panel__body {
	.checkbox {
		margin: 0 0.5rem 0 0 !important;
	}

	.accordion .accordion-button {
		font-size: $font-size-medium;

		&.collapsed {
			font-weight: 500;
		}
	}

	.accordion .panel__title--sub {
		height: 30px;
	}

	.accordion .panel__title--sub h4,
	.accordion .panel__title--sub .h4 {
		font-size: $font-size-medium;
	}
}
﻿/*---------------------------*\
* #ASSOCIATE
\*---------------------------*/

.modal--associate {
	h3 {
		font-size: $font-size-h4;
		margin: 1rem 0 0;
	}

	article .panel {
		border: 0 none;
		padding: 0;
	}

	.action-footer {
		padding: 0.75rem 0 0;
		border-top: 1px solid $border-color;
	}

	.scroll-vert {
		max-height: calc(100vh - 420px) !important;
	}
}

.action-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
﻿/*
 * Login theme
 */

.is-logged-out {
	background-color: $gray-light;

	.content {
		background-color: transparent;
	}

	.login-brand {
		margin: 5rem 0 4rem;
	}

	.card {
		width: 600px;
		margin: 0 auto;
		background-color: $white;
		border-radius: 15px;
		box-shadow: 0 12px 18px rgba(0, 0, 0, 0.3);
		border: 0 none;
	}

	.card-header {
		background-color: $brand-primary-medium-dark;
		;
		border-radius: 13px 13px 0 0;

		h1 {
			color: $white;
			font-size: 2.5rem;
			margin: 1.5rem 0;
		}
	}

	.card-footer {
		background-color: $gray-lighter;
		border-top: none;
		padding: 2rem;
		border-radius: 0 0 13px 13px;
	}

	.login-step {
		padding: 3rem;
	}
}
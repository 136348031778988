﻿/*---------------------------*\
* #TYPOGRAPHY
\*---------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.panel__title,
.panel__title--sub,
.title--overline {
	font-family: $headings-font-family;
}

h1 {
	font-size: $font-size-h1;
	font-weight: $headings-font-weight-strong;
}

h2 {
	font-size: $font-size-h2;
	font-weight: $headings-font-weight-strong;
}

h3 {
	font-size: $font-size-h3;
	font-weight: $headings-font-weight-strong;
}

h4 {
	font-size: $font-size-h4;
	font-weight: $headings-font-weight;
}

h5 {
	font-size: $font-size-h5;
	font-weight: $headings-font-weight;
}

h6 {
	font-size: $font-size-h6;
	font-weight: $headings-font-weight;
}

a {
	color: $brand-highlight;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: $brand-secondary-navy;
		text-decoration: none;
	}

}

.bold {
	font-weight: 600;
}

.title--overline,
a.title--overline,
a:focus.title--overline,
a:visited.title--overline {
	font-size: $font-size-tiny;
	text-transform: uppercase;
	color: $gray;
}

.text-tiny {
	font-size: $font-size-tiny;
}

small,
.small,
.text-small {
	font-size: $font-size-small;
}

.text-medium {
	font-size: $font-size-medium;
}

.text-large {
	font-size: $font-size-large;
}

.text-larger {
	font-size: $font-size-larger;
}

.text-success {
	color: $brand-success;
}

.text-danger {
	color: $brand-danger;
}

.text-warning-dark {
	color: $brand-warning-dark;
}

//TODO: Fix the labelling for warning when not working in RC branch
.text-warning-med-dark {
	color: $brand-warning;
}

.text-dark-grey,
.text-dark-gray {
	color: $gray-darker;
}

.text-grey,
.text-gray,
.btn.text-grey {
	color: $gray-medium-light;
}

.show-line-breaks {
	white-space: pre-line;
	word-break: break-word;
	/* changed to break-word to fix word breaking issue on Notes field. */
}

.score-success {
	padding: 2px 5px;
	font-size: $font-size-tiny;
	background: $brand-success-light;
	border-radius: $border-radius-small;

	strong {
		color: $brand-success;
	}
}

.score-warning {
	padding: 2px 5px;
	font-size: $font-size-tiny;
	background: $brand-warning-lighter;
	border-radius: $border-radius-small;

	strong {
		color: $brand-warning-dark;
	}
}

.score-danger {
	padding: 2px 5px;
	font-size: $font-size-tiny;
	background: $brand-danger-lighter;
	border-radius: $border-radius-small;

	strong {
		color: $brand-danger;
	}
}

.elided-text {
	max-width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* makes select boxes in firefox not as ugly */
@supports (-moz-appearance:none) {
	SELECT {
		-moz-appearance: none !important;
		background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
		background-position: calc(100% - 5px) center !important;
	}
}
﻿/*-----------------------------------------*\
* #PANELS
\*-----------------------------------------*/

.panel {
	background-color: $white;
	border-radius: $border-radius-large;
	padding: 1rem;

	.no-panel & {
		padding: 0;
	}
}

.panel__title {
	border-radius: $border-radius-large $border-radius-large 0 0;
}

.dashboard-panel-head {
	height: 35px;
}

.panel__title h3,
.panel__title .h3,
h3.panel__title {
	font-size: $font-size-panel-title;
	font-weight: $headings-font-weight;
	color: $brand-primary;
	margin: 0;
}

.panel__ops {
	.btn-icon i {
		font-size: 1rem;

		&.fa-times,
		&.fa-plus {
			font-size: 1.25rem;
		}

		&.fa-search {
			font-size: 0.875rem;
		}
	}
}

.panel__title--sub h4,
h4.panel__title--sub {
	font-size: $font-size-larger;
	margin: 0 0 8px;
	color: $gray-darker;
	font-weight: 600;
}

.panel__title--sub i {
	font-size: $font-size-base;
	color: $gray-medium-light;
}

.accordion {
	border-radius: $border-radius-base;

	&>.accordion-item {
		&:first-of-type {
			border-radius: $border-radius-base $border-radius-base 0 0;
		}

		&:last-of-type .accordion-button.collapsed {
			border-radius: 0 0 $border-radius-base $border-radius-base;
		}

		&>.accordion-header {
			padding: 0.75rem 0.75rem;
			border-bottom: 0;
			background: $gray-lighter;

			.drag__handle {
				font-size: $font-size-h4;
			}
		}
	}

	.accordion-button {
		padding: 0;
		font-size: $font-size-h6;
		font-weight: 600;
		padding: 0;
		width: 100%;
		color: $gray;
		text-align: left;
		background: none;
		z-index: 1;

		&::after {
			content: none;
		}

		&.collapsed {
			color: $brand-highlight;
			font-weight: 500;
		}

		&:not(.collapsed) {
			color: inherit;
			background: none;
			box-shadow: none;
		}

		&:disabled {
			color: $gray-medium;
		}

	}

	&:hover .accordion-button.collapsed {
		color: $brand-secondary-navy;
	}

	.accordion-expanded-items {
		position: absolute;
		right: 40px;
		top: 9px;
		z-index: 2;

		.btn-icon i {
			padding-left: .25rem;
			padding-right: .25rem;
		}
	}
}

.accordion .accordion-button[disabled] {
	color: $gray-medium;
}

.toggle-chevron {
	transition: all 0.5s ease;
}

.toggle-chevron.open {
	transform: rotate(90deg);
}

/* for workflow modals */
.workflow__panels .panel {
	border: 0 none;
}

/* Add Item Panel */
.add-panel {
	border: 2px solid $brand-success-medium;
	border-radius: $border-radius-base;

	&.warning {
		border-color: $brand-warning-light;
	}

	.btn-light,
	.btn-success {
		padding: 0.25rem 0.625rem;
		min-width: 35px;
		border-radius: $border-radius-small;
	}

	.btn-edit {
		background-color: $brand-warning-medium;
	}

	.btn-icon-top {
		position: relative;
		top: -11px;
		right: -10px;
	}
}